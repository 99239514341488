<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="cookie"></company-submenu>

    <div class="submenu-content">
      <div>
        <p></p>
        <h2><b>Ma Laboratories Cookie Notice</b></h2>
        <p>Last updated as of: 01/01/2020</p>
        <p>
          Ma Labs uses cookies and similar technologies on its Site. You can
          find out more about cookies and how to control them in the information
          below. This Cookie Notice explains how we use cookies throughout in
          connection with the Site and your related choices.
        </p>
        <p>
          Capitalized terms used in this Cookie Notice but not defined here will
          have the meanings given to them in our
          <router-link :to="{ name: 'PrivacyPolicy'}">Privacy&nbsp;Policy</router-link>.
        </p>
        <p>
          If you do not accept the use of these cookies, please disable them
          following the instructions in this Cookie Notice, for instance by
          changing your browser settings so that cookies on the Site cannot be
          placed on your computer or mobile device.
        </p>
        <h3><b>What Are Cookies?</b></h3>
        <p>
          Like most websites, we use cookies and other standard Internet
          technologies to help us improve our Site. Cookies do lots of different
          jobs, like letting you navigate between pages efficiently, remembering
          your preferences, and generally improving the user experience. They
          can also help to ensure that advertisements you see online are more
          relevant to you and your interests.
        </p>
        <p>
          A cookie is a small text file that is stored on a user's computer for
          record-keeping purposes. When you visit, access or use the Site, we
          may by means of cookies, beacons, tags, scripts, and/or similar
          technologies automatically collect technical information, including
          Device and Usage Information and other similar technical information.
        </p>
        <p>
          A cookie can be classified by its lifespan and the domain to which it
          belongs.
        </p>
        <p>By lifespan, a cookie is either a:</p>
        <ul>
          <li>
            Session cookie, which is erased when the user closes the browser; or
          </li>
          <li>
            Persistent cookie which remains on the user's computer/device for a
            pre-defined period of time.
          </li>
        </ul>
        <p>
          As for the domain to which it belongs, there are either:
        </p>
        <ul>
          <li>
            First-party cookies which are set by the web server of the visited
            page and share the same domain (in this case
            <router-link :to="{name: 'Home'}">www.malabs.com</router-link>); or
          </li>
          <li>
            Third-party cookies stored by a different domain to the visited
            page's domain. This can happen when the webpage references a file,
            such as JavaScript, located outside its domain.
          </li>
        </ul>
        <p>
          We use both session and persistent cookies, and enable first and
          third-party cookies. Ma Labs tracks users use of the Site, but does
          not track users across third party websites.
        </p>
        <p>
          Some of the cookies or similar technologies that we used are "strictly
          necessary" in that they are essential to the Site we provide. This
          includes cookies that capture and store users' preferences, account
          settings, and certain login information (mainly for convenience, user
          verification, and account security). Other cookies or similar
          technologies help us improve our Site, measure audiences or enable
          advertising.
        </p>
        <p>
          For our users in the EEA, please note that to the extent that your
          consent is required to allow cookies (except with respect to strictly
          necessary cookies), we will obtain your consent, which you may
          withdraw at any time.
        </p>
        <h3><b>How and Why do We Use Cookies?</b></h3>
        <p ><i>Strictly Necessary Cookies</i></p>
        <p>
          These cookies are necessary for the Site to function and cannot be
          switched off in our systems. They are usually only set in response to
          actions made by you, which amount to a request for access to our Site,
          such as setting your privacy preferences, logging in or filling in
          forms. You can set your browser to block or alert you about these
          cookies, but if you do, some parts of the Site will not work.
        </p>
        <p><i>Performance Cookies</i></p>
        <p>
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our Site. They help us to know
          which pages are the most and least popular and see how visitors
          navigate the Site. Performance cookies are used to help us with our
          analytics, including to:
        </p>
        <ul>
          <li>
            Compile statistics and analytics about your use of and interaction
            with the Site, including details about how and where our Site are
            accessed, how often you visit or use the Site, the date and time of
            your visits, your actions on the Site, and information regarding
            in-game activities, and other similar traffic, usage, and trend data
            (see
            <router-link :to="{ name: 'PrivacyPolicy'}">Device and Usage Information</router-link>
            above). These analytic tools allow us to better understand the
            functionality of our mobile software on your device and gain
            insights about how mobile software is used in order to improve your
            experience;
          </li>
          <li>
            Gather important functionality, testing, and performance data about
            our Site, including performance data related to the Site, such as
            networking activity, CPU load, and resource usage, among other
            relevant data;
          </li>
          <li>
            Moderate user behavior on the Site, such as protecting against
            payment fraud and other inappropriate activities; and
          </li>
          <li>
            Gather demographic information about our user base.
          </li>
        </ul>
        <p>
          We use third-party tools and technologies to help us gather this
          information. For instance, we use Google Analytics to collect and
          process certain analytics data with technologies such as tracking
          pixels. Google provides some additional privacy options described
          <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank"
            >here</a
          >
          with respect to Google Analytics cookies. To opt out from Google
          Analytics, you can download a plug-in by clicking
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.
        </p>
        <p>
          In addition, for Consumers, we have configured Google Analytics to
          disable the sharing of personal information with other Google Site.
        </p>
        <h3><b>Your Choices</b></h3>
        <p>
          Most web and mobile device browsers automatically accept cookies, but
          if you prefer, you can change your browser to prevent that or to
          notify you each time a cookie is set. Some browser manufacturers
          provide comprehensive help relating to cookie management in their
          products. Please see your web browser provider's settings for more
          information.
        </p>
        <p>
          You can also learn more about cookies by visiting
          <a href="https://www.allaboutcookies.org/verify" target="_blank"
            >www.allaboutcookies.org</a
          >, which includes additional useful information on cookies and how to
          block cookies using different types of browsers or mobile devices.
        </p>
        <p>
          <i
            >Please note, however, that by blocking or deleting all cookies used
            on the Site, you may not be able to take full advantage of the Site
            and you may not be able to properly log on to the Site.</i
          >
        </p>
        <h3><b>Changes to this Cookie Notice</b></h3>
        <p>
          Ma Labs may update this Cookie Notice from time to time, at its sole
          discretion. If so, we will post an updated Cookie Notice within the
          Site. Changes, modifications, additions, or deletions will be
          effective immediately on their posting to the Site. We encourage you
          to review this Cookie Notice regularly for any changes. Your continued
          use of the Site and/or your continued provision of personal
          information to us after the posting of such notice will be subject to
          the terms of the then-current Privacy Notice and Cookie Notice. If you
          continue to use the Site you will be deemed to have accepted the
          change.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { ref } from "vue";

export default {
  name: "About",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Cookie Notice", link: "CookieNotice"})

    return { breadcrumb_links }
  },
};
</script>

<style scoped>
.outer {
  padding: 100px 100px;
}
h2 {
  margin-bottom: 15px;
}
.h2 {
  font-size: 30px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Segoe, Arial;
  font-weight: 500;
  line-height: 1.1;
  color: #555555;
  margin: 0;
  padding: 0;
}
</style>
